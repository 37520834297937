/* The following line can be included in a src/App.scss */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~bootstrap/scss/bootstrap';

body {
  display: flex;
  justify-content: center;
  background-color: whitesmoke;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a:link {
  text-decoration: none;
}

a:hover {
  color: whitesmoke;
}

